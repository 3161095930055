@import 'global.scss';

.card {
  margin: 0 -20px;
  padding: 24px 20px;
  height: 268px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 190, 0.15);
  background-image: url('/assets/img/volnaSale.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $color-base-0;
  transition: all 0.2s ease-in-out;

  @include tablet {
    margin: 0;
    height: auto;
    border-radius: 24px;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url('/assets/img/volnaSale.jpeg');

    &:hover {
      border-radius: 24px 24px 24px 0;
      box-shadow: 0px 25px 45px 0px rgba(0, 0, 190, 0.2);
      transition: all 0.2s ease-in-out;
    }
  }

  @include desktop-max {
    background-image: url('/assets/img/volnaSale.jpeg');
  }
}

.headerInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header {
  display: block;
  font-family: 'TT Norms Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  @include desktop-max {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.32px;
  }
}

.infoCol {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.info {
  @include paragraph-p5;
}

.button {
  &__root {
    margin-top: auto;
    height: 48px;
    width: 100%;
    border-radius: 12px;
    background-color: $color-base-0;
    color: $color-black;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    outline: none;

    &:hover {
      background-color: $color-base-200;
    }

    &:active {
      background-color: $color-base-300;
    }

    &[data-disabled] {
      background-color: $color-base-200;
      color: $color-base-500;
    }

    @include tablet {
      width: 233px;
    }

    @include desktop-max {
      height: 40px;
    }
  }
}
