@import 'global.scss';

.card {
  margin-top: 32px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 436px;
  border-radius: 24px;
  background: linear-gradient(270deg, #9d009d 0%, $color-magenta-100 100%);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 190, 0.15);
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  @include tablet {
    margin: 0;
    height: auto;
  }

  @include desktop-max {
    max-width: 530px;
  }

  &:hover {
    @include tablet {
      border-radius: 24px 24px 24px 0;
      box-shadow: 0px 25px 45px 0px rgba(0, 0, 190, 0.2);
      transition: all 0.2s ease-in-out;
    }
  }

  &__animated {
    animation: gradientChange 0.8s ease-in-out 1;
    background: linear-gradient(270deg, #9d009d, #9d009d, #dd0285);
    background-size: 400% 400%;
  }
}

.tariffPage {
  margin-top: $gap-horizontal;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  height: 356px;
  position: relative;
  border-radius: 24px;
  background: linear-gradient(270deg, #9d009d 0%, #eb008c 100%);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 190, 0.15);
  transition: all 0.2s ease-in-out;
  gap: $corner-radius-large;

  @include tablet {
    height: 248px;
  }

  &__animated {
    animation: gradientChange 0.8s ease-in-out 1;
    background: linear-gradient(270deg, #9d009d, #9d009d, #dd0285);
    background-size: 400% 400%;
  }
}

@keyframes gradientChange {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.link {
  display: flex;
  align-items: baseline;
  height: fit-content;
  margin-bottom: 12px;
  user-select: none;

  @include desktop-max {
    height: 36px;
  }

  &__text {
    display: block;
    font-family: 'TT Norms Pro';
    font-style: normal;
    font-weight: 500;
    color: $color-base-0;
    font-size: 24px;
    line-height: 28px;

    @include desktop-max {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.32px;
    }
  }

  &__chevron {
    padding-top: 5px;
  }
}

.content {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: $color-base-0;
  @include heading-h2;

  &__price {
    @include label-l2-m;

    span {
      @include label-l3;
    }

    &__tariffPage {
      @include tablet {
        margin-right: 15%;
        margin-left: auto;
      }

      @include desktop {
        margin-right: 20%;
      }
    }
  }

  &__tariffPage {
    position: absolute;
    top: 40px;
    right: 0;
  }
}

.oldPrice {
  text-decoration: line-through;
}

.description {
  color: $color-base-0;
  @include paragraph-p5;
  align-self: flex-start;
  user-select: none;
}

.cylinder {
  height: 100%;
  position: relative;
}

.numbers {
  position: relative;
  text-align: right;
  width: 100%;
  height: 48px;
  overflow: hidden;
  margin: 0;

  &__tariffPage {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet {
      min-width: 600px;
    }
  }
}

.animation {
  animation:
    0.5s normal moveY,
    0.5s normal appearAnimation;
}

.fadingAnimation {
  animation: 0.5s ease-in-out fadingAnimation;
}

@keyframes moveY {
  from {
    transform: translate(0, -100px);
  }

  to {
    transform: translate(0, 0px);
  }
}

@keyframes appearAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadingAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.phoneNumber {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;

  @include tablet {
    right: calc(calc(100% - 300px) / 2);
    width: fit-content;
  }

  @include desktop {
    right: 80px;
    width: fit-content;
  }

  span {
    display: inline-block;
    text-align: center;
    min-width: 17.5px;

    @include desktop {
      min-width: 20px;
    }
  }

  &__tariffPage {
    width: 100%;
    padding: 0 20px;
    height: 48px;

    @include tablet {
      right: 20px;
      width: 358px;

      span {
        min-width: 20px;
      }
    }

    @include desktop {
      padding: 0;
      right: 74px;
      width: 380px;

      span {
        min-width: 24px;
      }
    }
  }
}

.phoneNumber,
.prefix {
  -webkit-background-clip: text;
  -webkit-text-fill-color: $color-base-0;
  user-select: none;
  cursor: pointer;
  text-align: center;
  @include heading-h3;
  user-select: none;

  @include desktop {
    @include heading-h2;
  }
}

.prefix {
  position: absolute;
  top: 0;
  right: 190px;
  color: $color-base-0;

  @include desktop {
    right: 280px;
  }
}

.buttons {
  display: grid;
  grid-template-columns: 100%;
  gap: $other-input;
  grid-template-areas:
    'All'
    'Next'
    'Shop';

  &__shop {
    grid-area: Shop;
  }
  &__all {
    grid-area: All;
  }
  &__next {
    grid-area: Next;
  }

  &__tariffPage {
    @include tablet {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'Shop All Next';
    }
  }

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'Shop All Next';
  }
}

.button {
  &__root {
    margin-top: auto;
    height: 48px;
    border-radius: 12px;
    background-color: $color-base-0;
    color: $color-black;
    @include label-l4-m;
    outline: none;

    &:hover {
      background-color: $color-base-200;
    }

    &:active {
      background-color: $color-base-300;
    }

    &[data-disabled] {
      background-color: $color-base-0;
      color: $color-base-500;
    }

    @include desktop-max {
      height: 40px;
    }

    &__tariffPage {
      @include desktop-max {
        height: 48px;
      }
    }
  }

  &__two {
    border: 1px solid $color-primary-0;
    color: $color-primary-0;
    background: transparent;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &[data-disabled] {
      background-color: transparent;
      color: $color-base-500;
      border: 1px solid $color-base-500;
    }
  }
}

.modal {
  display: none;
}

.flashBloc {
  margin: -24px -20px;
  position: relative;
  width: 100%;
  bottom: -72px;
  min-height: 248px;
  overflow: hidden;
  border-radius: 24px;
}

.flash {
  position: absolute;
  object-fit: fill;
  top: -50px;
  align-items: center;
  left: -10px;
  width: 900px;
  user-select: none;

  .background {
    height: 400px;
    width: 900px;
  }

  &__animated {
    animation: flashChange 1.1s ease-in-out infinite;
  }
}

@keyframes flashChange {
  0% {
    rotate: 0deg;
    filter: hue-rotate(0deg);
  }
  50% {
    top: -30px;
    rotate: 4.5deg;
    filter: hue-rotate(30deg);
  }
  100% {
    rotate: 0deg;
    filter: hue-rotate(0deg);
  }
}

.iphone {
  position: absolute;
  bottom: 0px;
  left: 30px;
  overflow: visible !important;
  user-select: none;

  img {
    rotate: 360deg;
    width: 370px;
    height: 280px;
  }
}

.disabled {
  background: $color-base-200;
  box-shadow: none;
  filter: grayscale(80%);

  &__flash {
    filter: grayscale(50%);
    opacity: 0.5;
  }
  &__phone {
    filter: contrast(50%) opacity(90%) brightness(150%) grayscale(60%);
  }
  &__text {
    -webkit-text-fill-color: $color-base-500;
  }
  &__title {
    color: $color-base-500;
  }
  &__button {
    color: $color-base-500;
    cursor: auto;
    &:hover {
      background-color: $color-base-0;
    }
  }
}
