@import 'global.scss';

.modalContainer {
  // position: relative;

  @include tablet {
    min-height: 350px;
    height: calc(100vh - 200px);
  }

  @include desktop {
    height: 630px;
  }
}

.searchNumber {
  gap: 12px;
  width: 100%;
}
.input {
  border-radius: 12px;
  height: 48px;
  &:focus {
    border-color: $color-base-500;
  }
}

.headerContent {
  display: grid;
  grid-template-columns: 290px 1fr;
  padding-top: 11px;
  gap: 12px;

  @include desktop-max {
    grid-template-columns: 310px 1fr;
  }
}

.buttonGroup {
  display: flex;
  // overflow-x: auto;
  gap: 8px;
}

.button {
  @include label-l4;
  color: $color-black;
  text-align: center;
  align-items: center;
  background-color: $color-base-0;
  &:hover {
    background: $color-magenta-100;
    color: $color-base-0;
    .oldPrice {
      color: $color-base-0;
    }
  }
  .oldPrice {
    color: $color-base-700;
  }
}

.btn {
  border: none;
}

.allNumbers {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 20px;

  @include tablet {
    max-height: calc(100vh - 384px);
    justify-content: space-between;
  }
  @include desktop {
    gap: 25px;
    justify-content: flex-start;
    max-height: 458px;
  }
}

.number {
  @include paragraph-p3;
  width: 191px;
  color: $color-base-700;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 16px;
  padding: 4px 8px;

  @include desktop {
    max-width: calc(100% / 3);
    width: auto;
    padding: 4px 8px;
    margin-bottom: -9px;
    cursor: pointer;
  }

  mark {
    color: $color-magenta-100;
    background: none;
  }

  mark > span {
    color: $color-base-700;
  }
}

.number:nth-last-child(-n + 3) {
  @include desktop {
    margin-bottom: 0;
  }
}

.bottomSheetStyles {
  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: $z-index-header-hover;
  }
}

.containerMobile {
  padding: 0 20px 20px 20px;
}

.title {
  @include heading-h4;
  padding-bottom: 12px;
  @include tablet {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
  }
}

.searchInputMob {
  width: 100%;
  padding-bottom: 12px;
}

.pickUp {
  padding: 0;
  @include label-l4-m;
  cursor: pointer;
  border-radius: $corner-radius-small;
  height: 48px;
  width: 100%;
  border: none;
  outline: none;
  background: $color-magenta-100;
  color: $color-base-0;
  &__disabled {
    color: $color-base-500;
    background: $color-base-200;
  }
}

.numbersWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  height: 385px;
  overflow-y: auto;
}

.numbersWrapperSelected {
  max-height: 240px;
}

.mobileBtnWrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 28px;
  align-items: center;
  gap: 16px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 190, 0.15);
  border-radius: 16px;
  z-index: 1;

  @include tablet {
    position: absolute;
    width: 375px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: $color-base-0;
  }

  @include desktop {
    width: 375px;
    padding: 16px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: $color-base-0;
  }
}
.btn {
  @include paragraph-p5;
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
  padding: 17px 32px;
  line-height: 14px;
  font-weight: 500;
}
.pick {
  color: $color-base-0;
  background-color: $color-magenta-100;
}
.cancel {
  color: $color-black;
  background-color: $color-base-100;
}
.numberActive {
  background-color: $color-magenta-100;
  color: $color-base-0;
  border-radius: 8px;

  mark {
    color: $color-base-0;
    background: none;
  }
}

.wrapperNumbers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.oldPrice {
  margin: 0px 4px 0px 0px;
  text-decoration: line-through;
  @include label-l5;
}

.activeButton {
  background-color: $color-magenta-100;
  color: $color-base-0;
  &:hover {
    background: $color-magenta-100;
  }
  .oldPrice {
    color: $color-base-100;
  }
}

.loadingRadius {
  border-radius: 15px;
}

.refContainer {
  width: 200px;
  height: 20px;
}

.scroll {
  @include scrollbar;
}

.noNumbersTitle {
  @include heading-h4;
  @include tablet {
    @include heading-h3;
  }
}

.noNumbersDesc {
  margin-top: 10px;
  display: inline-block;
  @include paragraph-p5;
}

.allNumbers {
  // overflow-y: auto;
}

.allNumbers::-webkit-scrollbar {
  width: 4px;
}

.allNumbers::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: $color-base-400;
}

.allNumbers::-webkit-scrollbar-thumb:window-inactive {
  background: $color-base-400;
}

.clearInput {
  bottom: 5px;
}

.categoriesWrapper {
  display: flex;
  flex-wrap: nowrap;
  // overflow-x: scroll;
  border: none;
  margin-top: 12px;
  gap: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.swiper {
  width: 100%;
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :global(.swiper-wrapper) {
    align-items: center;
    height: fit-content;
  }
  :global(.swiper-slide) {
    width: auto !important;
  }
  :global(.swiper-pagination) {
    @include swiper-pagination;
  }
  :global(.swiper-pagination-bullet) {
    @include swiper-pagination-bullet;
  }
  :global(.swiper-pagination-bullet-active) {
    @include swiper-pagination-bullet-active;
  }
  :global(.swiper-button-next) {
    @include swiper-button-next;
    opacity: 1;
    right: 0;
    top: calc(50% + 6px);
  }
  :global(.swiper-button-prev) {
    @include swiper-button-prev;
    opacity: 1;
    left: 0;
    top: calc(50% + 6px);
  }
  :global(.swiper-button-disabled) {
    @include swiper-button-disabled;
  }
}
