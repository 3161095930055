@import 'global.scss';

.root {
  display: flex;
  width: calc(100% + 2px);
  position: fixed;
  bottom: -1px;
  left: -1px;
  z-index: 200;
  margin: 0 -1px;
  padding: 12px 20px;
  border-radius: 0px;
  border: 1px solid $color-base-0;
  background: $color-base-0;

  @include tablet {
    margin: auto;
    padding: 12px 20px;
    width: max-content;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 182, 0);
    box-shadow: 0px -15px 30px 0px rgba(235, 0, 140, 0.15);
    position: sticky;
    bottom: 8px;
    z-index: 199;
  }
}

.content {
  &__wrapper {
    padding: 0 20px 24px 20px;
    display: flex;
    flex-direction: column;
    gap: $corner-radius-large;
    @include tablet {
      padding: 24px 20px;
    }
  }
  &__title {
    @include heading-h5;
    margin: auto;
    @include tablet {
      @include heading-h2;
      margin: 0;
    }
  }
  &__number_block {
    display: flex;
    align-items: center;
    @include only_mobile {
      text-align: center;
    }
  }
  &__number {
    @include label-l2;
    color: $color-magenta-100;
    text-align: center;
    font-weight: 500;
    @include only_mobile {
      margin-top: -15px;
      margin: auto;
    }
  }
  &__time {
    @include label-l4;
    margin-left: 10px;
  }
  &__options {
    display: flex;
    flex-direction: column;
  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: $corner-radius-large;
    border-radius: $corner-radius-large;
    background-color: $color-base-100;

    &__promo {
      padding-bottom: 36px;
    }
  }
  &__desc {
    padding: 0;
    margin: 0;
    @include paragraph-p5;
    color: $color-base-900;

    @include desktop-max {
      @include paragraph-p4;
    }
  }
  &__button {
    width: 100%;
    @include desktop-max {
      width: 420px;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  gap: 40px;
  align-items: center;
  @include tablet {
    display: block;
  }
  @include desktop-max {
    display: flex;
  }

  &__payment {
    width: 83px;
    text-align: center;
    line-height: 18px;
  }

  &__payment_tablet {
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    @include desktop-max {
      display: flex;
      font-size: 16px;
      width: 184px;
    }
  }

  &__text {
    color: $color-base-900;
    &:first-of-type {
      @include paragraph-p6;
      text-wrap: nowrap;
    }
    &:last-of-type {
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.16px;
    }
  }
}

.bottomsheet {
  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: $z-index-header-hover !important;
  }
  
  &[data-rsbs-is-dismissable='true'] [data-rsbs-backdrop] {
    z-index: 199;
    // opacity: 0.3; //поялвяется баг мигание и блок боттом кнопки
  }

  &[data-rsbs-is-dismissable='true'] [data-rsbs-scroll] {
    max-height: 600px; //для se 494px ПОПРАВИТЬ
  }

  &[data-rsbs-is-dismissable='true'] [data-rsbs-overlay] {
    padding-top: 10px;
    width: 100%;
    height: fit-content;
    max-height: 96%;
    overflow-y: scroll;
    box-shadow: 0px -15px 30px 0px rgba(235, 0, 140, 0.15);
    border-radius: 24px 24px 0 0;
    z-index: 200;
  }
}

.shadow {
  box-shadow: 0px -15px 30px 0px rgba(235, 0, 140, 0.15);
}

.closeIcon {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.355);
  z-index: -1;
}
.overlay_active {
  z-index: 200;
}

.price {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  &__old {
    @include label-l3-m;
    color: $color-base-700;
    text-decoration: line-through;
  }

  &__period {
    @include label-l6;
    color: $color-base-900;
    position: absolute;
    bottom: -20px;
    left: 0;
  }
}